import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import ContentBlockWithImage from '../components/UI/ContentBlockWithImage';
const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <ContentBlockWithImage
      title={data.aboutJson.title}
      image={data.aboutContentImage.childImageSharp.fluid}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: data.aboutJson.content.childMarkdownRemark.html,
        }}
      />
    </ContentBlockWithImage>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    aboutContentImage: file(relativePath: { eq: "images/about_us.png" }) {
      childImageSharp {
        fluid(maxHeight: 200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
