import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './Container'

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 25% 75%;
    grid-gap: 4rem;
    padding: 0 4rem;
    margin: 2rem 0;
`;
const ContentBlockWithImage = ({ image, children, title }) => {
    return (
        <Container>
            <ContentWrapper>
                <div >
                    <Img fluid={image} alt={title} />
                </div>
                <div>
                    <div>
                        <div>
                            <h1>{title}</h1>
                            {children}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </Container>
    );
};

ContentBlockWithImage.propTypes = {
    image: PropTypes.object.isRequired,
    children: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ContentBlockWithImage;
